import React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import Register from '../pages/auth/Register'

import { Analytics, CustomFields, Feedback, Hotel, Hotels } from '../pages'

// import Dashboard from '../pages/dashboard'
// import Feedback from '../pages/dashboard/Feedback'
// import Payment from '../pages/dashboard/Payment'
import Error404 from '../pages/error/Error404'
import Error500 from '../pages/error/Error500'
// import CleaningAndSupplies from '../pages/guest/CleaningAndSupplies'
// import DeviceGuide from '../pages/guest/DeviceGuide'
// import HelpDesk from '../pages/guest/HelpDesk'
// import HotelDetails from '../pages/guest/HotelDetails/index'
// import Restaurant from '../pages/guest/restaurant/Restaurant'
import Maintenance from '../pages/other/Maintenance'
// import Rooms from '../pages/rooms'

// components
import PrivateRoute from './PrivateRoute'
import Root from './Root'
import ViewInvoice from '../pages/invoices/ViewInvoice'
import ViewPlans from '../pages/NewPlans'
import ViewPlan from '../pages/NewPlan'
import NewInvoice from '../pages/NewPlan/NewInvoice'

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'))
const Logout = React.lazy(() => import('../pages/auth/Logout'))
// const Confirm = React.lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'))
//const Register = React.lazy(() => import("../pages/auth/Register"));
// const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"));

// // apps
// const Profile = React.lazy(() => import('../pages/profile'))
// const Notifications = React.lazy(() => import('../pages/notifications'))
// const Report = React.lazy(() => import('../pages/report'))
// const Community = React.lazy(() => import('../pages/community'))
// const Invoices = React.lazy(() => import('../pages/invoices'))
// const User = React.lazy(() => import('../pages/user/User'))
// const UserView = React.lazy(() => import('../pages/user/Profile'))
// const Team = React.lazy(() => import('../pages/team/Team'))
// // - chat
// const ChatApp = React.lazy(() => import('../pages/apps/Chat/'))

// // -other
// const Starter = React.lazy(() => import('../pages/other/Starter'))
// // const Profile = React.lazy(() => import('../pages/other/Profile'));
// const Activity = React.lazy(() => import('../pages/other/Activity'))
// const Invoice = React.lazy(() => import('../pages/other/Invoice'))
// const Pricing = React.lazy(() => import('../pages/other/Pricing'))

// // uikit
// const UIElements = React.lazy(() => import('../pages/uikit'))

// // widgets
// const Widgets = React.lazy(() => import('../pages/widgets/'))

// // forms
// const BasicForms = React.lazy(() => import('../pages/forms/Basic'))
// const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'))
// const FormValidation = React.lazy(() => import('../pages/forms/Validation'))
// const FormWizard = React.lazy(() => import('../pages/forms/Wizard'))
// const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'))
// const Editors = React.lazy(() => import('../pages/forms/Editors'))

// // charts
// const Charts = React.lazy(() => import('../pages/charts/'))

// // tables
// const BasicTables = React.lazy(() => import('../pages/tables/Basic'))
// const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'))

export interface RoutesProps {
  path: RouteProps['path']
  name?: string
  component?: RouteProps['component']
  route?: any
  exact?: RouteProps['exact']
  icon?: string
  header?: string
  roles?: string[]
  children?: RoutesProps[]
}

// root routes
const rootRoute: RoutesProps = {
  path: '/',
  exact: true,
  component: () => <Root />,
  route: Route,
}

// // dashboards
// const dashboardRoutes: RoutesProps = {
//   path: '/dashboard',
//   name: 'Dashboard',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   component: Dashboard,
// }

// const userRoutes: RoutesProps = {
//   path: '/user',
//   name: 'User',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'user',
//   children: [
//     {
//       path: '/user',
//       name: 'User',
//       component: User,
//       route: PrivateRoute,
//       exact: true,
//     },
//     {
//       path: '/user/:userId',
//       name: 'User View',
//       component: UserView,
//       route: PrivateRoute,
//     },
//   ],
// }

// const teamRoutes: RoutesProps = {
//   path: '/team',
//   name: 'Team',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'user',
//   component: Team,
// }

// const communityAppRoutes: RoutesProps = {
//   path: '/community',
//   name: 'Community',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'share-2',
//   component: Community,
// }

// const profileAppRoutes: RoutesProps = {
//   path: '/profile',
//   name: 'Profile',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'share-2',
//   component: Profile,
// }

// const invoicesRoutes: RoutesProps = {
//   path: '/invoices/all',
//   name: 'Invoices',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'file-text',
//   component: Invoices,
// }

// const feedbackRoutes: RoutesProps = {
//   path: '/dashboard/feedback',
//   name: 'feedback',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'package',
//   component: Feedback,
// }
// const paymentRoutes: RoutesProps = {
//   path: '/dashboard/payment',
//   name: 'payment',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'package',
//   component: Payment,
// }

// const hotelDetailsRoutes: RoutesProps = {
//   path: '/guest/hotel-details',
//   name: 'Hotel details',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'package',
//   component: HotelDetails,
// }
// const restaurantRoutes: RoutesProps = {
//   path: '/guest/restaurant',
//   name: 'Restaurant',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'package',
//   component: Restaurant,
// }
// const cleaningAndSuppliesRoutes: RoutesProps = {
//   path: '/guest/cleaning-and-supplies',
//   name: 'Cleaning and Supplies',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'package',
//   component: CleaningAndSupplies,
// }
// const deviceGuideRoutes: RoutesProps = {
//   path: '/guest/device-guide',
//   name: 'Device guide',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'package',
//   component: DeviceGuide,
// }
// const helpDeskRoutes: RoutesProps = {
//   path: '/guest/help-desk',
//   name: 'Help Desk',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'package',
//   component: HelpDesk,
// }

// const roomsRoutes: RoutesProps = {
//   path: '/rooms',
//   name: 'Rooms',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'briefcase',
//   component: Rooms,
// }

// const chatAppRoutes: RoutesProps = {
//   path: '/apps/chat',
//   name: 'Chat',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'message-square',
//   component: ChatApp,
// }

// const notificationRoute: RoutesProps = {
//   path: '/notification',
//   name: 'Send notification',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'bell',
//   component: Notifications,
// }

// const reportRoute: RoutesProps = {
//   path: '/reports',
//   name: 'Reports',
//   route: PrivateRoute,
//   roles: ['Admin'],
//   icon: 'alert-octagon',
//   component: Report,
// }

// const appRoutes = [chatAppRoutes]

// // pages
// const extrapagesRoutes: RoutesProps = {
//   path: '/pages',
//   name: 'Pages',
//   icon: 'package',
//   header: 'Custom',
//   children: [
//     {
//       path: '/pages/starter',
//       name: 'Starter',
//       component: Starter,
//       route: PrivateRoute,
//     },
//     {
//       path: '/pages/profile',
//       name: 'Profile',
//       component: Profile,
//       route: PrivateRoute,
//     },
//     {
//       path: '/pages/activity',
//       name: 'Activity',
//       component: Activity,
//       route: PrivateRoute,
//     },
//     {
//       path: '/pages/invoice',
//       name: 'Invoice',
//       component: Invoice,
//       route: PrivateRoute,
//     },
//     {
//       path: '/pages/pricing',
//       name: 'Pricing',
//       component: Pricing,
//       route: PrivateRoute,
//     },
//   ],
// }

// // ui
// const uiRoutes: RoutesProps = {
//   path: '/components',
//   name: 'Components',
//   icon: 'package',
//   header: 'UI Elements',
//   children: [
//     {
//       path: '/components/ui-elements',
//       name: 'UIElements',
//       component: UIElements,
//       route: PrivateRoute,
//     },
//     {
//       path: '/components/widgets',
//       name: 'Widgets',
//       component: Widgets,
//       route: PrivateRoute,
//     },
//     {
//       path: '/forms',
//       name: 'Forms',
//       children: [
//         {
//           path: '/forms/basic',
//           name: 'Basic Elements',
//           component: BasicForms,
//           route: PrivateRoute,
//         },
//         {
//           path: '/forms/advanced',
//           name: 'Form Advanced',
//           component: FormAdvanced,
//           route: PrivateRoute,
//         },
//         {
//           path: '/forms/validation',
//           name: 'Form Validation',
//           component: FormValidation,
//           route: PrivateRoute,
//         },
//         {
//           path: '/forms/wizard',
//           name: 'Form Wizard',
//           component: FormWizard,
//           route: PrivateRoute,
//         },
//         {
//           path: '/forms/upload',
//           name: 'File Upload',
//           component: FileUpload,
//           route: PrivateRoute,
//         },
//         {
//           path: '/forms/editors',
//           name: 'Editors',
//           component: Editors,
//           route: PrivateRoute,
//         },
//       ],
//     },
//     {
//       path: '/components/charts',
//       name: 'Charts',
//       component: Charts,
//       route: PrivateRoute,
//     },
//     {
//       path: '/tables',
//       name: 'Tables',
//       children: [
//         {
//           path: '/tables/basic',
//           name: 'Basic',
//           component: BasicTables,
//           route: PrivateRoute,
//         },
//         {
//           path: '/tables/advanced',
//           name: 'Advanced',
//           component: AdvancedTables,
//           route: PrivateRoute,
//         },
//       ],
//     },
//   ],
// }

// auth
const authRoutes: RoutesProps[] = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
    route: Route,
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: Register,
    route: Route,
  },
  // {
  //   path: "/auth/confirm",
  //   name: "Confirm",
  //   component: Confirm,
  //   route: Route,
  // },
  {
    path: '/auth/forget-password',
    name: 'Forget Password',
    component: ForgetPassword,
    route: Route,
  },
  // {
  //   path: "/auth/lock-screen",
  //   name: "Lock Screen",
  //   component: LockScreen,
  //   route: Route,
  // },
  {
    path: '/auth/logout',
    name: 'Logout',
    component: Logout,
    route: Route,
  },
]

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
    route: Route,
  },
  {
    path: '/error-404',
    name: 'Error - 404',
    component: Error404,
    route: Route,
  },
  {
    path: '/error-500',
    name: 'Error - 500',
    component: Error500,
    route: Route,
  },
]

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = []

  routes = routes || []
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item)

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

const analyticsRoute: RoutesProps = {
  path: '/analytics',
  name: 'Analytics',
  route: PrivateRoute,
  component: Analytics,
}

// const customFieldsRoute: RoutesProps = {
//   path: '/custom-fields',
//   name: 'Custom Fields',
//   route: PrivateRoute,
//   component: CustomFields,
// }

const hotelsRoute: RoutesProps = {
  path: '/hotels',
  name: 'Hotels',
  route: PrivateRoute,
  component: Hotels,
}

const feedbackRoute: RoutesProps = {
  path: '/feedback',
  name: 'Feedback',
  route: PrivateRoute,
  component: Feedback,
}

const hotelRoute: RoutesProps = {
  path: '/hotel/:hotelId',
  name: 'Hotel',
  route: PrivateRoute,
  component: Hotel,
}

const viewInvoicesRoute: RoutesProps = {
  path: "/invoice/:invoiceId",
  name: "View Invoice",
  route: PrivateRoute,
  component: ViewInvoice,
};

const invoiceNewView: RoutesProps = {
  path: "/print-invoice/subscriptions/:subId",
  name: "View Invoice",
  route: PrivateRoute,
  component: NewInvoice,
};

// const viewSettlementRoute: RoutesProps = {
//   path: '/settlement/:settlementId',
//   name: 'View Settlement',
//   route: PrivateRoute,
//   component: ViewSettlement,
// }

const viewPlanRoute: RoutesProps = {
  path: '/plans/:subId',
  name: 'Plan',
  route: PrivateRoute,
  component: ViewPlan,
}

const viewPlansRoute: RoutesProps = {
  path: '/plans',
  name: 'Plans',
  route: PrivateRoute,
  component: ViewPlans,
}


// All routes
const authProtectedRoutes = [
  rootRoute,
  analyticsRoute,
  // customFieldsRoute,
  hotelsRoute,
  hotelRoute,
  feedbackRoute,
  viewInvoicesRoute,
  // viewSettlementRoute
  viewPlanRoute,
  viewPlansRoute,
  invoiceNewView
]
const publicRoutes = [...authRoutes, ...otherPublicRoutes]

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes])
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes])
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
}
